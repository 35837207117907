// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";

const loadingStyle = {
  padding: "0rem 1.75rem",
  color: "white",
  marginTop: "2rem",
  lineHeight: "1.5rem",
  textAlign: "center"
};

const highlightStyle = {
  color: "white"
};

class Submenu extends Component {
  constructor(props) {
    super(props);
    this.state = { items: this.props.items, activeItemId: null, swipeUpClicked: false, loading: this.props.inTransition };
    this.canInteract = this.props.canInteract;
  }

  componentDidMount() {
    this.props.resetExternalActiveItemId();
  }


  componentWillReceiveProps(newProps) {
    this.canInteract = newProps.canInteract;

    if (newProps.inTransition !== undefined)// check if new props update have intransition or not
    {
      this.setState({
        loading: newProps.inTransition
      });
    }

    if (newProps.externalActiveItemId !== undefined) {
      console.log(newProps.externalActiveItemId);
      this.setState({
        activeItemId: newProps.externalActiveItemId
      })
    }

  }

  onSubMenuItemClick(event, contextMenu) {
    if (!this.canInteract) {
      return;
    }
    this.setState({ activeContextMenuItem: contextMenu });
    this.props.onSubMenuItemClick(event, contextMenu);
  }

  onHeadingClick(event, contextMenu) {
    if (!this.canInteract) {
      return;
    }

    if (this.state.loading) {
      return;
    }

    this.props.onHeadingClick(event, contextMenu);
  }

  onSwipeUpClicked(event, expended) {
    if (!this.canInteract) {
      return;
    }
    this.props.onSwipeUpClicked(expended);
  }

  render() {
    const { subMenus, activeMenuItem, item } = this.props;

    return (
      <>
        {(this.state.loading) ?
          (null)
          : (
            <>
              <div className={`submenu-container active expended`}>
                <div className={`second-level-nav ${item.id === activeMenuItem.id ? "" : "d-none"}`}>
                  
                  <span className="swipe-up-btn hide-on-desktop" onClick={(event) => this.onSwipeUpClicked(event, true)}></span>
                  
                  {item.isContextMenuTitle ? (<h3 className="second-level-nav__title" onClick={event => this.onHeadingClick(event, item)}>{item.contextMenuTitle}</h3>) : ""}
                  
                  <ul className={`scrollable-part ${item.isContextMenuTitle ? "" : "scrollable-part__notitle"}`}>
                    {subMenus.map((contextMenu) => (
                      <li className={`${contextMenu.isHighlighted ? "active" : ""}`}
                        key={contextMenu.id} onClick={(event) => this.onSubMenuItemClick(event, contextMenu)}><a style={(this.state.activeItemId === contextMenu.id) ? (highlightStyle) : (null)} >{contextMenu.name}</a></li>
                    ))}
                  </ul>
                
                </div>
              </div>
            </>
          )}
      </>
    );
  }
}

export default Submenu;
